<template>
    <b-row>

        <b-col
                cols="12"
                md="12"
                class=" mb-1"
        >

            <div class="pb-2 mt-1 table-top-toolbar">

                <b-row>

                    <b-col
                            cols="12"
                            md="6"
                            class="d-flex align-items-center justify-content-start mt-1 mb-md-0"
                    >
                        <!--<label>{{ $t('label_show') }}</label>-->
                        <v-select
                                v-model="tableData.perPage"
                                :searchable="false"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="tableData.perPageOptions"
                                :clearable="false"
                                class="per-page-selector d-inline-block mx-50"
                        />
                        <!--<label>{{$t('label_records')}}</label>-->
                    </b-col>

                    <b-col
                            cols="12"
                            md="6"
                    >
                        <div class="d-flex align-items-center justify-content-end">

                            <b-button v-if="$can('edit', MODULE_PREFIX + '.' + PREFIX + 's')"
                                      variant="primary"
                                      @click="tabItem = false; $bvModal.show(PREFIX + '-modal')"
                            >
                                <span class="text-nowrap">{{ $t('label_add_note') }}</span>
                            </b-button>
                        </div>
                    </b-col>

                </b-row>
                <b-row>
                    <b-col
                            cols="12"
                            md="12"
                            class="mt-1"
                    >
                        <span class="text-primary">{{$t('label_all_comments') + ': '}}</span> <span
                            class="badge mr-1 ml-auto badge-light-warning badge-pill">{{this.tableTotal}}</span>

                    </b-col>
                </b-row>

            </div>


            <div class="table-container-wrap">
                <b-table
                        :ref="PREFIX+'_TABLE'"
                        class="position-relative transited-table"
                        :items="tableItems"
                        responsive
                        no-border-collapse
                        :busy="isBusy"
                        :fields="columnsDefs"
                        primary-key="id"
                        :sort-by.sync="tableData.sortBy"
                        no-local-sorting
                        :sort-desc.sync="tableData.sortDesc"
                        show-empty
                        :tbody-transition-props="{ name: 'flip-list'}"
                        :empty-text="$t('label_no_table_data')"
                        thead-class="hidden"
                >

                    <template #head()="data">
                        <span class="text-secondary">{{ $t(data.label).ucFirst() }}</span>
                    </template>

                    <template #cell(id)="data">

                        <b-media
                                tag="div"
                                no-body
                                style="min-height: 90px;position: relative;"
                        >


                            <b-media-body>
                                <div class="mail-details">
                                    <div class="mail-items">
                                        <h5 class="mb-25">
                                            <span class="text-primary">{{data.item.title}}</span>

                                            {{$t('label_posted_on')}} - <span class="text-primary">
                                                    {{formatDate(data.item.postedtime, 'DD.MM.YYYY HH:mm', 'unix')}}</span>&nbsp;

                                            <span class="text-success">
                                                <!--{{data.item.title}}-->
                                                </span>


                                        </h5>

                                    </div>

                                </div>

                                <div class="mail-message">
                                    {{data.item.description}}
                                </div>
                                <b-dropdown
                                        v-if="$can('edit', MODULE_PREFIX + '.' + PREFIX + 's')"
                                        class="line-actions"
                                        variant="link"
                                        no-caret
                                        :right="$store.state.appConfig.isRTL"
                                >

                                    <template #button-content>
                                        <feather-icon
                                                icon="MoreVerticalIcon"
                                                size="16"
                                                class="align-middle text-body"
                                        />
                                    </template>


                                    <b-dropdown-item
                                            @click="tabItem = data.item; $bvModal.show(PREFIX + '-modal')">
                                        <feather-icon icon="EditIcon"/>
                                        <span class="align-middle ml-50">{{ $t('label_edit') }}</span>
                                    </b-dropdown-item>

                                    <b-dropdown-item
                                            @click="$emit('delete-item', data.item.id);deleteItem(data.item.id)">
                                        <feather-icon icon="TrashIcon"/>
                                        <span class="align-middle ml-50">{{ $t('label_delete') }}</span>
                                    </b-dropdown-item>
                                </b-dropdown>
                            </b-media-body>
                        </b-media>


                    </template>

                </b-table>
            </div>

            <div class="mx-2 mb-2 ">
                <b-row>

                    <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                        <span class="text-muted">{{ $t('label_showed') + ' '+ $t('label_from').toLowerCase() + ' ' + dataMeta.from + ' ' + $t('label_to_date') + ' ' + dataMeta.to + ' ' + $t('label_of') + ' ' + dataMeta.of }} </span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >

                        <b-pagination
                                v-model="tableData.currentPage"
                                :total-rows="tableTotal"
                                :per-page="tableData.perPage"
                                first-number
                                last-number
                                class="mb-0 mt-1 mt-sm-0"
                                prev-class="prev-item"
                                next-class="next-item"
                        >
                            <template #prev-text>
                                <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                />
                            </template>
                        </b-pagination>

                    </b-col>

                </b-row>
            </div>

            <process-note-modal
                    :module-item="moduleItem"
                    :edited-item="tabItem"
                    @item-edited="refreshDataTable()"
                    @item-added="refreshDataTable()"
            ></process-note-modal>


        </b-col>

    </b-row>
</template>

<script>
    import {

        BTable, BDropdownItem, BDropdown, BPagination, BMedia,
        BMediaAside,
        BMediaBody,
        BAvatar,
        BButton,

    } from 'bootstrap-vue'
    import processNoteModal from './../modals/processNoteModal'
    import vSelect from 'vue-select'
    import VuePerfectScrollbar from 'vue-perfect-scrollbar'
    import {NOTE_PREFIX as PREFIX, MODULE_PREFIX} from './../moduleHelper'

    export default {
        components: {

            BTable, BDropdownItem, BDropdown, BPagination, BMedia,
            BMediaAside,
            BMediaBody,
            BAvatar,
            vSelect,
            VuePerfectScrollbar,
            BButton,
            processNoteModal
        },
        props: ['moduleItem'],
        data() {
            return {
                MODULE_PREFIX,
                PREFIX,
                perfectScrollbarSettings: {
                    maxScrollbarLength: 100,
                },
                columnsDefs: [
                    {label: 'label_#', key: 'id'},
                ],

                tabItem: false,

                isBusy: false,
                tableItems: [],
                tableTotal: 0,
                tableData: {
                    currentPage: 1,
                    perPage: 100,
                    searchQuery: '',
                    sortBy: 'id',
                    sortDesc: true,
                    perPageOptions: [10, 25, 50, 100],
                },
            }
        },
        watch: {
            tableData: {
                handler: function (newVal, old) {
                    let self = this;
                    self.refreshTableId = setTimeout(function () {
                        clearInterval(self.refreshTableId);
                        self.refreshDataTable();
                    }, 100);
                },
                deep: true
            },
        },
        computed: {
            dataMeta: (context) => {
                return {
                    from: context.tableData.perPage * (context.tableData.currentPage - 1) + (context.tableItems.length ? 1 : 0),
                    to: context.tableData.perPage * (context.tableData.currentPage - 1) + context.tableItems.length,
                    of: context.tableTotal,
                }
            },

        },
        methods: {
            refreshDataTable: function () {
                this.isBusy = true;

                this.async('get', '/' + this.MODULE_PREFIX + '_' + this.PREFIX + 's', {
                    params: {
                        [this.MODULE_PREFIX + '_id']: this.moduleItem.id,
                        length: this.tableData.perPage,
                        start: (this.tableData.currentPage - 1) * this.tableData.perPage,
                        search: this.tableData.searchQuery,
                        sort_by: this.tableData.sortBy,
                        sort_desc: this.tableData.sortDesc ? true : null,
                    }
                }, function (resp) {

                    this.tableItems = resp.data.items;
                    this.tableTotal = resp.data.total;
                    this.isBusy = false;
                });
            },
            deleteItem(id) {
                this.confirmDeleting((result) => {

                    this.async('delete', '/' + this.MODULE_PREFIX + '_' + this.PREFIX + 's/' + id, {params: {}}, function (resp) {
                        this.refreshDataTable();
                    });
                })
            },
        },
        created() {

            this.refreshDataTable();
        },
    }
</script>
<style lang="scss">
    .line-actions {
        position: absolute;
        right: 0;
        top: 0;
    }

    .email-media-list {
        width: 100%;
    }


</style>