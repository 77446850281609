<template>
    <div class="partial--text">

        <slide-up-down :active="!showRest" :duration="1000">
        <span v-on="$listeners"  class="partial-result">{{short_text}}</span>
        <!--<span v-on="$listeners" v-else class="partial-result" v-html="">{{removeTags(short_text)}}</span>-->
        <div  v-if="text_length > short_text.length && !showRest" @click="showRest=true" class="d-flex justify-content-center"><feather-icon title="Clear" data-toggle icon="ChevronDownIcon" size="21"/></div>
        </slide-up-down>

        <slide-up-down :active="showRest" :duration="1000">
            <span v-on="$listeners" v-if="!isHtml">{{text}}</span>
            <span v-on="$listeners" v-else="" v-html="text"></span>
            <div class="d-flex justify-content-center">
                <feather-icon title="Clear" @click="showRest=false" data-toggle icon="ChevronUpIcon" size="21"/>
            </div>
        </slide-up-down>

    </div>
</template>


<script>

    import SlideUpDown from 'vue-slide-up-down'

    export default {
        props: {
            isHtml: {default: false, type: Boolean},
            text:{default: '', type: String},
            limit:{default: 30, type: Number},
        },
components:{
    SlideUpDown
},
        data() {
            return {
                showRest:false,
                text_length: 0
            }

        },
        computed:{
            // remainedText: function(){
            //     let shortEnd = this.short_text.length;
            //     return this.text.slice(shortEnd);
            // },
            // result: function(){
            //    return this.short_text + this.remainedText;
            // },
            short_text: function(){
                let preparedText = this.removeTags(this.text);
                this.text_length = preparedText.length;
                // let shortText =
                return preparedText.split(' ').slice(0, this.limit).join(' ');
            }
        },
        methods: {
            openFileUploader() {
                this.$refs['file-uploader'].click();
            },
            removeTags(text){
                let regex = /(<([^>]+)>)/ig;
                // if(!this.remainedText) {
                    return text.replace(regex, "");
                // } else {
                //     return text;
                // }

            }
        },


    }
</script>


